@font-face {
  font-family: Minecraft Regular;
  font-style: normal;
  font-weight: 400;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(../assets/fonts/MinecraftRegular.woff2) format("woff");
  font-display: swap;
}
@font-face {
  font-family: Minecraft Regular;
  font-style: normal;
  font-weight: 500;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(../assets/fonts/MinecraftBold.woff2) format("woff");
  font-display: swap;
}
@font-face {
  font-family: Minecraft Regular;
  font-style: italic;
  font-weight: 400;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(../assets/fonts/MinecraftItalic.woff2) format("woff");
  font-display: swap;
}
@font-face {
  font-family: Minecraft Regular;
  font-style: italic;
  font-weight: 500;
  src: local("Lucida Console"), local("Courier New"), local(monospace), url(../assets/fonts/MinecraftBoldItalic.woff2) format("woff");
  font-display: swap;
}
.font-Minecraft_Regular {
  font-family: "Minecraft Regular", serif;
}


@font-face {
  font-family: Whitney;
  font-style: normal;
  font-weight: 300;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-normal-300.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: italic;
  font-weight: 300;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-italic-300.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-normal-400.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: italic;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-italic-400.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: normal;
  font-weight: 500;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-normal-500.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: italic;
  font-weight: 500;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-italic-500.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: normal;
  font-weight: 600;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-normal-600.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: italic;
  font-weight: 600;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-italic-600.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: normal;
  font-weight: 700;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-normal-700.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  font-style: italic;
  font-weight: 700;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/whitney-italic-700.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: normal;
  font-weight: 300;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-normal-300.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: italic;
  font-weight: 300;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-italic-300.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-normal-400.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: italic;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-italic-400.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: normal;
  font-weight: 500;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-normal-500.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: italic;
  font-weight: 500;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-italic-500.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: normal;
  font-weight: 700;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-normal-700.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Normal;
  font-style: italic;
  font-weight: 700;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc-italic-700.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: normal;
  font-weight: 300;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-normal-300.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: italic;
  font-weight: 300;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-italic-300.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-normal-400.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: italic;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-italic-400.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: normal;
  font-weight: 500;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-normal-500.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: italic;
  font-weight: 500;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-italic-500.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: normal;
  font-weight: 700;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-normal-700.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: italic;
  font-weight: 700;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-italic-700.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: normal;
  font-weight: 800;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-normal-800.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: ABC Ginto Nord;
  font-style: italic;
  font-weight: 800;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(../assets/fonts/abc_nord-italic-800.woff2) format("woff2");
  font-display: swap;
}



@font-face {
  font-family: Achieve Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Achieve-Regular.ttf) format("truetype");
  font-display: swap;
}
.font-Achieve_Regular {
  font-family: "Achieve Regular", serif;
}

@font-face {
  font-family: BatuphatScript;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/BatuphatScript.otf) format("opentype");
  font-display: swap;
}
.font-BatuphatScript {
  font-family: "BatuphatScript", serif;
}

@font-face {
  font-family: BonaNova Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/BonaNova-Regular.ttf) format("truetype");
  font-display: swap;
}
.font-BonaNova_Regular {
  font-family: "BonaNova Regular", serif;
}

@font-face {
  font-family: BonheurRoyale Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/BonheurRoyale-Regular.ttf) format("truetype");
  font-display: swap;
}
.font-BonheurRoyale_Regular {
  font-family: "BonheurRoyale Regular", serif;
}

@font-face {
  font-family: Brygada1918 VariableFont;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Brygada1918-VariableFont_wght.ttf) format("truetype");
  font-display: swap;
}
.font-Brygada1918_VariableFont {
  font-family: "Brygada1918 VariableFont", serif;
}

@font-face {
  font-family: Foxbot;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Foxbot.ttf) format("truetype");
  font-display: swap;
}
.font-Foxbot {
  font-family: "Foxbot", serif;
}

@font-face {
  font-family: Important;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Important.ttf) format("truetype");
  font-display: swap;
}
.font-Important {
  font-family: "Important", serif;
}

@font-face {
  font-family: InterTight VariableFont;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/InterTight-VariableFont_wght.ttf) format("truetype");
  font-display: swap;
}
.font-InterTight_VariableFont {
  font-family: "InterTight VariableFont", serif;
}

@font-face {
  font-family: LakehurstGothic;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/LakehurstGothic-Medium.ttf) format("truetype");
  font-display: swap;
}
.font-LakehurstGothic {
  font-family: "LakehurstGothic", serif;
}

@font-face {
  font-family: Mansalva Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Mansalva-Regular.ttf) format("truetype");
  font-display: swap;
}
.font-Mansalva_Regular {
  font-family: "Mansalva Regular", serif;
}

@font-face {
  font-family: Paragon Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Paragon-Regular.otf) format("opentype");
  font-display: swap;
}
.font-Paragon_Regular {
  font-family: "Paragon Regular", serif;
}

@font-face {
  font-family: RubikDirt Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/RubikDirt-Regular.ttf) format("truetype");
  font-display: swap;
}
.font-RubikDirt_Regular {
  font-family: "RubikDirt Regular", serif;
}

@font-face {
  font-family: SairaStencilOne Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/SairaStencilOne-Regular.ttf) format("truetype");
  font-display: swap;
}
.font-SairaStencilOne_Regular {
  font-family: "SairaStencilOne Regular", serif;
}

@font-face {
  font-family: Simpel Medium;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Simpel-Medium.otf) format("opentype");
  font-display: swap;
}
.font-Simpel_Medium {
  font-family: "Simpel Medium", serif;
}

@font-face {
  font-family: Streetwear;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Streetwear.otf) format("opentype");
  font-display: swap;
}
.font-Streetwear {
  font-family: "Streetwear", serif;
}

@font-face {
  font-family: SugarSnow;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/SugarSnow.ttf) format("truetype");
  font-display: swap;
}
.font-SugarSnow {
  font-family: "SugarSnow", serif;
}

@font-face {
  font-family: Veselka4F;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/Veselka4F.ttf) format("truetype");
  font-display: swap;
}
.font-Veselka4F {
  font-family: "Veselka4F", serif;
}

@font-face {
  font-family: WindSong Regular;
  font-style: normal;
  font-weight: 400;
  src: local(monospace), url(../assets/fonts/custom/WindSong-Regular.ttf) format("truetype");
  font-display: swap;
}
.font-WindSong_Regular {
  font-family: "WindSong Regular", serif;
}
