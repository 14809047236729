@import url(fonts.css);

:root {
  --header-background: #100B0A;
  --content-background: #0F1828;
  --card-background: #16355D;
  --footer-background: #100B0A;

  --font-primary:Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
  --font-display:"ABC Ginto Normal","Helvetica Neue",Helvetica,Arial,sans-serif;
  --font-headline:"ABC Ginto Nord","Helvetica Neue",Helvetica,Arial,sans-serif;

  --content-banner: url("../assets/img/background_moon.webp");
  --profile-banner: url("src/assets/img/background_profile.webp"); /*url("../assets/img/special/christmas/background_profile.webp");*/
}


body {
  font-family: var(--font-primary);
  background-color: rgb(19, 21, 22);
  color: #e8e8e8;
  height: 100%;
  width: 100%;
  padding: 0;
}

.header {
  font-family: var(--font-headline);
  width: 100%;
  background-color: var(--header-background);
  height: 7em;
}

.header > .logo > .icon {
  margin-left: 1em!important;
}

.header > .logo > .icon, .header > .logo > .text {
  margin-top: 1em;
  margin-left: .5em;
  max-height: 5em;
  width: auto;
}

/*
.header > .brand > .search {
  padding: 1em 1.5em;
  float: right;
}

.header > .brand > .search > input {
  right: 1em;
  width: 23em;
}*/

.header > nav {
  padding-top: 5em;
}

.header > nav > .navigator {
  width: fit-content;
  border-radius: 0 0 1em 1em;
  margin-left: auto;
  margin-right: auto;
}

.header > nav > .navigator > .links {
  position: relative;
  overflow: hidden;
  top: -4em;
}

.header > nav > .navigator > .links > a {
  float: left;
  display: block;
  padding: 15px;
  background-color: var(--header-background);
  color: var(--bs-gray-300);
  text-decoration: inherit;
}

.header > nav > .navigator > .links > a:first-child {
  border-radius: 0 0 0 1em;
}

.header > nav > .navigator > .links > a:last-child {
  border-radius: 0 0 1em 0;
}

.header > nav > .navigator > .links > .active {
  background-color: var(--bs-gray-400);
  color: var(--bs-primary)
}

.header > nav > .navigator > .links > a:hover {
  background-color: var(--bs-gray-400);
  color: var(--bs-primary)
}

.header > nav > .account {
  position: relative;
  top: -7.5em;
  padding-top: 0;
  width: fit-content;
  border-radius: 0 0 1em 1em;

  margin-left: auto;
  margin-right: 5.6em;
}

.header > nav > .account > .links {
  overflow: hidden;
}

.header > nav > .account > .links > a {
  float: left;
  display: block;
  padding: 15px;
  color: var(--bs-gray-300);
  text-decoration: inherit;
  background-color: var(--header-background);
}

.header > nav > .account > .links > a:not(:first-child):not(:last-child) {
  border-right: #ffffff solid thin;
}

.header > nav > .account > .links > a:first-child {
  border-radius: 0 0 0 1em;
}

.header > nav > .account > .links > a:last-child {
  border-radius: 0 0 1em 0;
}

.header > nav > .account > .links > .active {
  background-color: var(--bs-gray-400);
  color: var(--bs-primary)
}

.header > nav > .account > .links > a:hover {
  background-color: var(--bs-gray-400);
  color: var(--bs-primary)
}


.header > nav > .account > .sub-links {
  position: relative;
  right: -1em;
  overflow: hidden;
  background-color: var(--header-background);
  border: white solid 0.2em;
  border-radius: 0 0 1em 1em;
  border-top: none;
  width: 13em;
  /*padding: .8em;*/
}

.header > nav > .account > .sub-links > ul {
  list-style: none;
  padding: 0;
}
.header > nav > .account > .sub-links > ul > li {
  width: 100%;
}
.header > nav > .account > .sub-links > ul > li > a {
  float: left;
  display: block;
  padding: 1em;
  color: var(--bs-gray-300);
  text-decoration: inherit;
  width: 100%;
}
.header > nav > .account > .sub-links > ul > li > .active {
  background-color: var(--bs-gray-400);
  color: var(--bs-primary)
}
.header > nav > .account > .sub-links > ul > li > a:hover {
  background-color: var(--bs-gray-400);
  color: var(--bs-primary);
}




.router-content {
  width: 100%;
  background-color: var(--content-background);
}

.page-noHeader {
  padding-top: 10.5em !important;
}

.page-header {
  padding-top: 24em;
  background-image: var(--content-banner);
  background-size: cover;
  background-position: bottom center;
  border-radius: 0;
  color: #fff;
}

.page-header-title > h5 {
  font-family: var(--font-headline);
  text-shadow: 5px 5px 10px black;
  font-size: 2.5em;
}

.page-header .breadcrumb-item {
  text-shadow: 5px 5px 10px black;
  font-size: 1em;

  background-color: rgba(24, 26, 27, 0.8);
  padding: 0.3em;
  box-shadow: 0 -5px 2em rgb(24, 26, 27);
}

.page-header .breadcrumb-item + .breadcrumb-item::before {
  font-family: 'Font Awesome 5 Pro', serif;
  font-weight: bold;
  content: '\f054';
}

.page-header .breadcrumb-item:first-child {
  padding-left: 0.5em;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.page-header .breadcrumb-item:last-child {
  padding-right: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.page-header > div > div > div > .breadcrumb > li > a {
  text-decoration: inherit;
  color: #fff;
  text-shadow: 5px 5px 10px black;
}

.page-header .page-block {
  padding: 35px 40px;
}

.page-content {
  font-family: var(--font-display);
  padding: 1em;
  overflow-wrap: break-word;
}

.page-footer {
  background-color: var(--footer-background);
  padding: 1em;
  box-shadow: 0 -5px 2em var(--footer-background);
}

.page-footer a {
  text-decoration: inherit;
}


.sub-title {
  border-bottom: 1px solid rgba(204, 204, 204, .35);
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #7B8C8FFF;
}


.card {
  border-radius: 10px;
  margin-bottom: 30px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;

  -webkit-box-shadow: rgba(55, 72, 80, 0.08) 0 1px 20px 0;
  box-shadow: rgba(55, 72, 80, 0.08) 0 1px 20px 0;
  border: none initial;
  background-color: var(--card-background); /*rgb(12, 65, 60);*/
}

.card .card-block {
  padding: 20px;
}

.card .card-block .sub-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}

.card .list-group-item {
  background-color: var(--card-background);
}

.resources a {
  color: #fff;
  text-decoration: inherit;
}

.resources .badge {
  font-size: .65em;
}


.form-control,.ng-select.form-select2, .ng-select.form-select2 .ng-select-container {
  color: rgb(209, 205, 199);
  background-color: var(--header-background);
  border-color: rgb(60, 65, 68);
}
.form-control:focus {
  color: rgb(209, 205, 199);
  background-color: var(--header-background);
  border-color: rgb(1, 57, 137);
  outline-color: initial;
  box-shadow: rgba(2, 80, 196, 0.25) 0 0 0 0.25rem;
}

.ng-select.form-select2 .ng-option, .ng-select input {
  color: rgb(209, 205, 199);
  background-color: var(--header-background);
}
.ng-select.form-select2 .ng-option.ng-option-disabled {
  background-color: #343434;
}
.ng-select.form-select2.ng-select-disabled .ng-select-container {
   background-color: #343434;
 }

.form-control:disabled, .form-control[readonly]  {
  background-color: #343434;
}

.form-control.ng-invalid, .form-select2.ng-invalid {
  border: 1px solid var(--bs-danger);
  border-radius: 0.25rem;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-control.ng-untouched, .form-select2.ng-untouched {
  border-color: rgb(60, 65, 68);
  background-image: none;
  padding-right: 0;
}
.input-group-text {
  background-color: #343434;
  color: rgb(209, 205, 199);
  border: none;
}


.btn {
  margin: 0.1em;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer;
}
.btn-small {
  padding: 0.375rem 0.75rem!important;
}
.btn-strong {
  padding: .9em 1.5rem!important;
  font-size: 1.6rem!important;
  border-radius: .3rem!important;
}
.btn-facebook {
  color: #fff;
  background-color: #1877F2;
  border-color: #0967e0;
}
.btn-twitter {
  color: #fff;
  background-color: #00acee;
  border-color: #0099d2;
}
.btn-pinterest {
  color: #fff;
  background-color: #E60023;
  border-color: #cc0120;
}

.btn-pateron {
  color: #fff;
  background-color: #f96854;
  border-color: #052d49;
}

.btn-paypal {
  color: #fff;
  background-color: #0079C1;
  border-color: #00457C;
}


.mini-profile img {
  width: 8%;
  /*margin-top: -1.5em;*/
  margin-right: 15px;
  vertical-align: top!important;
  border-style: none;
  border-radius: 50%;
}
.mini-profile .d-inline-block {
  margin-top: 1em;
}
.mini-profile p {
  margin-bottom: 0;
}

canvas {
  width: 100%;
  height: auto;
}

.page-link {
  color: #ffffff;
  background-color: var(--card-background);
  border-color: #0d6efd;
}

.swal2-container {
  z-index: 100000!important;
}

.swal2-popup {
  background-color: var(--content-background)!important;
  color: #fff!important;
}

.swal2-confirm {
  background-color: var(--bs-success)!important;
}

.swal2-timer-progress-bar {
  background-color: var(--bs-success)!important;
}

.bg-danger_ {
  background-color: var(--bs-danger)!important;
}


.divider {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: x-large;
  margin-top: 1.5em;
  margin-bottom: .8em;
}
.divider::after,
.divider::before {
  content: "";
  border: 1px dotted #5b5b5b;
  flex: 1;
}
.divider:not(:empty)::before {
  margin-right: 0.25em;
}
.divider:not(:empty)::after {
  margin-left: 0.25em;
}


.btn {
  text-transform: none!important;
}

.btn-xbox {
  background-color: #107C10;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-microsoft {
  background-color: #00A4EF;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-teamspeak {
  background-color: #2580c3;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-discord {
  background-color: #5865F2;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-google {
  background-color: #4285F4;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-twitch {
  background-color: #6441A4;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-github {
  background-color: #333;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}


body[layout-type="light"] {
  --header-background: #fff;
  --content-background: #fff;
  --footer-background: #fff;
}

.table {
  color: #e8e8e8;
}
.table > tbody > tr:last-child > td, .table > tbody > tr:last-child > th {
  border-bottom: none;
}

.text-role-modder {
  color: #af81ff;
}
.text-role-helper {
  color: #af81ff;
}
.text-role-supporter {
  color: #4dffa3;
}
.text-role-developer {
  color: #ff3259;
}
.text-role-translator {
  color: #00972d;
}
.text-role-designer {
  color: #f300a4;
}
.text-role-partner {
  color: #ec0045;
}
.text-role-content-creator {
  color: #d41ab9;
}
.text-role-vip {
  color: #d41ab9;
}

.text-red-yellow {
  background: linear-gradient(to right, #FF3D3D,
  #FF5C5C,
  #FFB05C,
  #FFD105);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.text-white-purple{
  background: linear-gradient(to right, #ffffff, #b257f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.text-white-blue {
  background: linear-gradient(to right, #ffffff, #00bdff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.text-white-red {
  background: linear-gradient(to right, #ffffff, #dc3c3c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.text-gradient {
  background: linear-gradient(to right, #FF3D3D,
  #FF5C5C,
  #FFB05C,
  #FFD105,
  #71FF05,
  #7bc2f1,
  #d4cdf8,
  #FF5CE9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}


.text-glitch {
  text-shadow: 2px 2px 0 #0000ff,
  0 2px 0 #ff0000;
  font-weight: bold;
}

.text-rainbow-shadow {
  text-shadow: 2px 2px 0 #eb452b,
  4px 4px 0 #efa032,
  6px 6px 0 #46b59b,
  8px 8px 0 #017e7f,
  10px 10px 0 #052939;
  font-weight: bold;
}

.text-rainbow {
  background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
  font-weight: bold;
}
@keyframes rainbow_animation {
  0%,100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}

.text-terminator {
  background: linear-gradient(to right, #ffffff,#ffffff, var(--test),#ffffff,#ffffff);
  -webkit-background-clip: text;
  color: transparent;
  animation: terminator_animation 2s infinite;
  background-size: 400% 100%;
  font-weight: bold;
}
@keyframes terminator_animation {
  0%,0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}


.editor > .NgxEditor, .NgxEditor__MenuBar {
  color: rgb(209, 205, 199)!important;
  background-color: var(--header-background)!important;
  border-top-color: rgb(60, 65, 68)!important;
  border-right-color: rgb(60, 65, 68)!important;
  border-bottom-color: rgb(60, 65, 68)!important;
  border-left-color: rgb(60, 65, 68)!important;
}
.NgxEditor__Dropdown--Item, .NgxEditor__Popup {
  color: rgb(209, 205, 199)!important;
  background-color: var(--header-background)!important;
}
.NgxEditor__MenuItem--IconContainer:hover {
  color: black;
}



/*
.ng-select-bottom {
  border: none!important;
  color: rgb(209, 205, 199)!important;
  background-color: var(--header-background)!important;
}
.select2-selection__choice {
  background-color: var(--card-background)!important;
}

.select2-dropdown {
  background-color: var(--card-background)!important;
}
*/

/*.ngx-file-drop__content {*/
.ngx-file-drop__drop-zone {
  height: 15em!important;
}
.ngx-file-drop__content {
  height: 100% !important;
}


.language {
  float: right;
  top: 2em;
  right: 2em;

  border-radius: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  width: 2.5em;
  height: 2.5em;
}
.language:hover {
  border-style: solid;
  border-color: #d1d2d4;
  border-width: 0.15em;
}


.social-card {
  min-height: 11em;
}
.social-card .social-avatar {
  height: 9.2em;
  border: 0.2rem #adadb8 solid;
  background-color: #f5f5f5
 }


.ng-select, .ng-select-container {
  color: rgb(209, 205, 199);
  background-color: var(--header-background);
  border-color: rgb(60, 65, 68);
}
.ng-select.form-select2 .ng-option.ng-option-disabled {
  background-color: #343434;
}
.ng-select.form-select2.ng-select-disabled .ng-select-container {
  background-color: #343434;
}
