@media (max-width: 576px) {
  .header > .navigator {
    display: none;
  }
}

@media (max-width: 1200px) {
  .header > .brand > div > .search {
    display: none;
  }

  .header > nav > .account {
    display: none;
  }
}
